import React from "react"

import classNames from "classnames"
import PropTypes from "prop-types"
import "./Divider.scss"

const Divider = ({ text, color }) => {
  const dividerClass = classNames({
    divider: true,
    "divider--none": text === "",
    "divider--white": color === "white",
  })

  return (
    <div className={dividerClass}>
      <span>{text}</span>
    </div>
  )
}

Divider.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
}

Divider.defaultProps = {
  text: "",
}

export default Divider
