import React from "react"

import SettingsComponent from "../components/Application/SettingsComponent"
import ProtectedRouteLayout from "../components/Layout/ProtectedRouteLayout"

const settings = () => {
  return (
    <ProtectedRouteLayout>
      <SettingsComponent />
    </ProtectedRouteLayout>
  )
}

export default settings
