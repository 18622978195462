import React, { useContext, useState, useEffect } from "react"

import PropTypes from "prop-types"

import { AuthenticationContext } from "../../context"
import mainAxios from "../../utils/axiosConfig"
import Divider from "../UI/Divider"
import AppNavbar from "./AppNavbar"
import HomeInfo from "./HomeInfo"

import "./SettingsComponent.scss"

const productionPlans = [
  {
    description: "",
    id: "pay_as_you_go",
    title: "Pay as you go",
  },
  {
    description: "",
    id: "subscription_1",
    title: "Single",
  },
  {
    description: "",
    id: "subscription_2",
    title: "Couple",
  },
  {
    description: "",
    id: "subscription_3",
    title: "Family",
  },
]

const stagingPlans = [
  {
    description: "",
    id: "pay_as_you_go",
    title: "Pay as you go",
  },
  {
    description: "",
    id: "price_1LmtTSB3zG1W97rdawy6IMX6",
    title: "Single",
  }
]

function SettingsComponent() {
  const {
    plans,
    changePlan,
    setError,
    checkIsAuthenticated,
    userSelectedPlan,
    user,
    updateUser,
    isLoadingRequest,
    setPlanList,
    setPlan,
  } = useContext(AuthenticationContext)

  const initialValue = {
    email: user.email || "",
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    mobile: user.mobile || "",
    postCode: user.postCode || "",
  }

  console.log(userSelectedPlan)

  const [selected, setSelected] = useState(userSelectedPlan)
  const [value, setValues] = useState(initialValue)
  const [validationText, setValidationText] = useState("Enter Mobile Number")
  const hardCodedPlans = process.env.NODE_ENV === 'production' ? productionPlans : stagingPlans

  useEffect(() => {
    if (checkIsAuthenticated()) {
      mainAxios
        .get("/stripe/plans")
        .then((response) => {
          const { data } = response.data.plans
          setPlanList(data)
        })
        .catch((error) => {
          setError([error])
        })
    }
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    let tempNum = value

    if (name === "mobile" && value.length > 10) {
      if (value[0] === "+") {
        tempNum = tempNum.slice(3, tempNum.length)
      } else {
        tempNum = tempNum.slice(0, 10)
      }
    }
    if (name === "mobile") {
      if (value.length < 10 && value[0] !== "0") {
        setValidationText("Mobile number should start with 0")
      } else if (value.length < 10 && value[0] === "0") {
        setValidationText("Enter valid mobile number")
      }
    }
    setValues((prev) => ({
      ...prev,
      [name]: name === "email" ? value.toLowerCase() : value,
      [name]: name === "mobile" ? tempNum : tempNum,
    }))
  }

  const PlanButton = ({ text, id, handleClick }) => {
    const styleObject = {
      price: "plan-button__price",
      text: "plan-button__text",
    }

    const styleObjectSelected = {
      price: "plan-button__price plan-button__price--selected",
      text: "plan-button__text plan-button__text--selected",
    }

    const getPlanPrice = (id) => {
      const planSelected = plans.filter((plan) => plan.id === id)
      if (planSelected[0]) {
        const amount = planSelected[0].amount / 100
        return `$${(Math.round(amount * 100) / 100).toFixed(2)}`
      } else return "-"
    }

    return (
      <div className="plan-button" onClick={() => handleClick(id)}>
        <div
          className={
            selected === id ? styleObjectSelected.text : styleObject.text
          }
        >
          <span>{text}</span>
        </div>
        <div
          className={
            selected === id ? styleObjectSelected.price : styleObject.price
          }
        >
          <span>{getPlanPrice(id)}</span>
        </div>
      </div>
    )
  }

  PlanButton.propTypes = {
    handleClick: PropTypes.func,
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }

  const handleSelect = (id) => {
    setPlan(id)
    setSelected(id)
  }
  const handleCharInputKeyDown = (e) => {
    if (
      !(e.key >= "0" && e.key <= "9") &&
      e.key !== "Backspace" &&
      e.key !== "Enter" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault()
    }
  }

  const handleSave = (e) => {
    const mobile = e.target.mobile.value
    e.preventDefault()

    const formData = new FormData()
    formData.append("email", value.email)
    formData.append("first_name", value.firstName)
    formData.append("last_name", value.lastName)
    formData.append("mobile", mobile)
    formData.append("postcode", value.postCode)
    formData.append("id", user.id)
    updateUser(formData)

    if (selected !== userSelectedPlan) {
      changePlan()
    }
  }
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)

  return (
    <div className="settings-container">
      <div className="settings-header">
        <AppNavbar setIsInfoOpen={setIsInfoOpen} isInfoOpen={isInfoOpen} />
      </div>
      <div className="settings-component">
        <div className="settings-component__body">
          <div className="settings-component__body__content">
            <div className="settings-component__body__content__form">
              <form
                className="settings-form"
                onSubmit={handleSave}
                id="update-form"
              >
                <div className="settings-form__item">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    required
                    placeholder="Please Enter"
                    value={value.firstName}
                    onChange={handleInputChange}
                    pattern="^(?!\s*$).+"
                    disabled={user.provider !== "email"}
                    title="Please Enter Firstname"
                  />
                </div>
                <div className="settings-form__item">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    required
                    placeholder="Please Enter"
                    value={value.lastName}
                    onChange={handleInputChange}
                    pattern="^(?!\s*$).+"
                    disabled={user.provider !== "email"}
                    title="Please Enter Lastname"
                  />
                </div>
                <div className="settings-form__item">
                  <label htmlFor="mobile">Mobile</label>
                  <input
                    id="mobile"
                    type="tel"
                    name="mobile"
                    required
                    placeholder="Please Enter"
                    value={value.mobile}
                    onChange={handleInputChange}
                    onKeyDown={handleCharInputKeyDown}
                    pattern="[0][0-9]{9}"
                    title={validationText}
                  />
                </div>
                <div className="settings-form__item">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="Please Enter"
                    value={value.email}
                    onChange={handleInputChange}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    disabled={user.provider !== "email"}
                    title="Please include a domain name in the email"
                  />
                </div>
                <div className="settings-form__item">
                  <label htmlFor="postCode">Postcode</label>
                  <input
                    type="number"
                    name="postCode"
                    required={user.provider === "email"}
                    placeholder="Please Enter"
                    value={value.postCode}
                    onChange={handleInputChange}
                  />
                </div>
              </form>
            </div>
            <div className="settings-component__body__content__divider">
              <Divider text="Plan" />
            </div>
            <div className="settings-component__body__content__buttongroup">
              {hardCodedPlans.map((item, index) => (
                <PlanButton
                  text={item.title}
                  key={index}
                  id={item.id}
                  handleClick={handleSelect}
                />
              ))}
            </div>
          </div>
          <div className="settings-component__body__description">
            <p>
              Please enter your contact information in the fields above. Thank
              you.
            </p>
          </div>
          <button
            type="submit"
            className="settings-component__body__save"
            disabled={isLoadingRequest}
            form="update-form"
          >
            Save
          </button>
        </div>
      </div>
      <div className="homescreen-component__email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
      {isInfoOpen && <HomeInfo setIsInfoOpen={setIsInfoOpen} />}
    </div>
  )
}

export default SettingsComponent
